// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medios-js": () => import("./../../../src/pages/medios.js" /* webpackChunkName: "component---src-pages-medios-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sobremi-js": () => import("./../../../src/pages/sobremi.js" /* webpackChunkName: "component---src-pages-sobremi-js" */),
  "component---src-pages-talleres-bingo-js": () => import("./../../../src/pages/talleres/bingo.js" /* webpackChunkName: "component---src-pages-talleres-bingo-js" */)
}

